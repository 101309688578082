<template>
  <div>
    <div id="date-selector">
      <v-icon @click="changeDate(-1)">mdi-chevron-left</v-icon>

      <v-menu
        ref="menu"
        v-model="menu"
        transition="scale-transition"
        offset-y
        min-width="auto">
        <template v-slot:activator="{}">
          <h2 @click="openDatePicker">{{ date ? date.format('L') : '' }}</h2>
        </template>
        <v-date-picker
          v-model="dateMenu"
          no-title
          scrollable>
        </v-date-picker>
      </v-menu>

      <v-icon
        :disabled="isNextDayDisabled"
        @click="changeDate(+1)"
        >mdi-chevron-right</v-icon
      >
    </div>
    <v-container>
      <counterGeolocation
        :geolocalisation="geolocalisation"
        :selectPeriod="selectPeriod" />
      <bleScanCharts
        v-if="date"
        @select="(select) => (selectPeriod = select)"
        :scan="scan"
        :date="date" />
    </v-container>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import bleScanService from '@/services/bleScan.services'
import countersGeolocationsServices from '@/services/countersGeolocations.services.js'
import bleScanCharts from '../../components/counters/bleScanCharts.vue'
import counterGeolocation from '../../components/counters/counterGeolocation.vue'
export default {
  props: ['id'],
  components: {
    bleScanCharts,
    counterGeolocation,
  },
  data: () => ({
    scan: [],
    geolocalisation: {},
    selectPeriod: null,
    menu: false,
    dateMenu: '',
  }),
  watch: {
    id(val) {
      if (val) {
        this.refresh()
      }
    },
    dateMenu(val) {
      if (val === this.date.format('YYYY-MM-DD')) {
        return
      }
      this.menu = false
      this.date = dayjs(val)
      this.debouncedRefresh()
    },
  },
  mounted() {
    if (!this.date) {
      this.date = dayjs()
    }
    this.refresh()
  },
  computed: {
    isNextDayDisabled() {
      return this.date ? this.date.isSame(dayjs(), 'day') : false
    },
    date: {
      get() {
        return this.$route.query.date ? dayjs(this.$route.query.date) : ''
      },
      set(value) {
        this.$router.replace({
          query: { date: value.format('MM/DD/YYYY') },
        })
      },
    },
  },
  methods: {
    changeDate(days) {
      this.date = this.date.add(days, 'day')
      this.debouncedRefresh()
    },
    debouncedRefresh: debounce(function () {
      this.refresh()
    }, 500),

    openDatePicker() {
      this.menu = true
      this.dateMenu = this.date.format('YYYY-MM-DD')
      console.log('openDatePicker', this.dateMenu)
    },

    async refresh() {
      if (this.date) {
        this.scan = []
        this.geolocalisation = {}

        const [{ data: scan }, { data: geolocalisation }] = await Promise.all([
          bleScanService.getCounterScan(
            this.id,
            this.date.startOf('day').toDate(),
            this.date.endOf('day').toDate()
          ),
          countersGeolocationsServices.getGeolocation(
            this.id,
            this.date.startOf('day').toDate(),
            this.date.endOf('day').toDate()
          ),
        ])
        this.scan = scan
        this.geolocalisation = geolocalisation
      }
    },
  },
}
</script>

<style scoped>
#date-selector {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1rem;
}
</style>
