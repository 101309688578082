<template>
  <div class="fulls">
    <v-card flat>
      <v-card-title>
        Counters
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details></v-text-field>
      </v-card-title>
      <counters-list :counters="countersFiltered" />
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import countersList from '../components/counters/countersList.vue'

export default {
  components: {
    countersList,
  },
  data() {
    return {
      search: sessionStorage.getItem('searchCounter') || '',
      fab: '',
    }
  },
  watch: {
    search(val) {
      sessionStorage.setItem('searchCounter', val)
    },
  },
  computed: {
    ...mapGetters(['counters']),
    countersFull() {
      return this.counters
    },
    countersFiltered() {
      if (this.search) {
        const search = this.search.toLowerCase()

        return this.countersFull.filter((elem) => {
          if (elem.id && elem.id.toLowerCase().includes(search)) {
            return true
          }
          if (elem.owner && elem.owner.toLowerCase().includes(search)) {
            return true
          }

          if (elem.firmware && elem.firmware.toLowerCase().includes(search)) {
            return true
          }

          if (elem.otherOwners && elem.otherOwners.length > 0) {
            if (
              elem.otherOwners.some((owner) => {
                if (owner?.toLowerCase().includes(search)) {
                  return true
                }
                return false
              })
            ) {
              return true
            }
          }

          return false
        })
      }
      return this.countersFull
    },
  },
  created() {
    this.getCountersPaginated()
  },
  methods: {
    ...mapActions(['getCountersPaginated']),
  },
}
</script>
